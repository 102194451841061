import React from "react";
import SEO from "../components/seo";
import H1 from "../components/h1";
import { listOfTestimonials } from "../data/listOfTestimonials";
import Testimonial from "../components/Testimonial";

const TestimonialsPage = () => {
	return (
		<div>
			<SEO
				title="Testimonials from our patients"
				keywords={[
					`Patient testimonials`,
					`Advanced GI Patient reviews and testimonials`,
					`Dr Frank Brennan reviews and testimonials`,
					`Dr Spiro Raftopoulos reviews and testimonials`,
					`Dr Ian Yusoff reviews and testimonials`,
					`Dr Dev Segarajasingam reviews and testimonials`,
					`Dr Sam Rao reviews and testimonials`,
					`Dr Claire Gordon reviews and testimonials`,
					`Dr Duy Pham reviews and testimonials`,
				]}
				description="See what patients have to say about Advanced GI WA"
			/>
			<H1 text="Testimonials" />
			{listOfTestimonials.map(testimonial => (
				<Testimonial testimonial={testimonial} />
			))}
		</div>
	);
};

export default TestimonialsPage;
